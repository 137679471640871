<template>
  <div id="vehicleAttendance">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addAttendance"
              type="primary"
              @click="addBtn"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-vehicleAttendanceTab-addBtn'
                )
              "
              ><i class="iconfont icon-jiahao"></i> 新建考勤</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column prop="plateNum" label="商户名称" align="center">
        </el-table-column>
        <el-table-column prop="plateNum" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="plateNum" label="联系方式" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="goToDetail(scope.row.leaseId)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-vehicleAttendanceTab-detail'
                )
              "
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <add-attendance-dialog ref="addAttendanceDialog" :getList="getList" />
  </div>
</template>
<script>
export default {
  components: {
    addAttendanceDialog: () => import("./addAttendanceDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    // this.getList();
    this.$message.error("车辆考勤没接口");
  },
  methods: {
    addBtn() {
      this.$message.error("没图，没接口");
      return;
      this.$refs.addAttendanceDialog.show = true;
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    goToDetail(adviceId) {
      this.$router.push({
        name: "adviceDetail",
        params: { id: adviceId },
      });
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/price/lease/list/byParkingLot", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
          parkingLotId: this.$route.params.id,
          type: "3",
        });
        if (res.code === 0) {
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#vehicleAttendance {
  .table-container {
    .table-filtrate {
      ul {
        .taigang {
          font-size: 12px;
          color: hsla(26, 100%, 64%, 0.96);
          span {
            color: hsla(217, 48%, 15%, 0.96);
            margin-right: 4px;
          }
        }
        li {
          .addAttendance {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .el-image {
                width: 53px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
